<template>
    <div class="mt-3 mb-3" style="z-index: 1 !important;">
        <h3> {{ props.label }}: </h3><v-btn v-if="model == undefined" @click="openDashboard"
            :singleFileFullScreen="false">Upload File</v-btn>
        <div v-if="model != undefined">

            <v-chip prepend-icon="mdi-file-pdf-box" @click="openUploaded" closable @click:close="abort">
                {{ upload_name }}
            </v-chip>
        </div>
        <div class="px-auto">
            <DashboardModal :uppy="uppy" :open="openModal" closeModalOnClickOutside showRemoveButtonAfterComplete />
        </div>
    </div>
</template>
<script setup>
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.min.css';
import XHR from '@uppy/xhr-upload';

import { DashboardModal } from '@uppy/vue';
import Tus from '@uppy/tus'
import Uppy from '@uppy/core';
import Webcam from '@uppy/webcam';

let openModal = ref(false)
let uploadedLink = ref("")
const uppy = new Uppy({
    restrictions: {
        maxFileSize: 5 * 1024 * 1024,
        maxNumberOfFiles: 1,
        allowedFileTypes: [".pdf"]
    }
})
const model = defineModel()
const auth = useAuthStore()
const props = defineProps(['label'])
let upload_name = ref("")
function openUploaded() {
    // console.log(uppy.getFiles()[0].tus.uploadUrl)
    window.open(uppy.getFiles()[0].preview, '_blank')
}
function openDashboard() {
    if (openModal.value == false) {
        openModal.value = true
    } else {
        openModal.value = false
        setTimeout(() => { openModal.value = true }, 100)
    }
}
uppy.use(Webcam)
uppy.use(XHR, { 
    endpoint: 'https://storage.atmicup.id/uploads',
    // endpoint: 'http://localhost:45781/uploads',
    headers: {
        Authorization: `Bearer ${auth.auth_token}`,
    },
    responseType: 'string'
 });
// uppy.use(Tus, {
//     endpoint: "https://storage.atmicup.id/files/",
//     headers: {
//         Authorization: `Bearer ${auth.auth_token}`,
//     },
//     onSuccess: (aa) => {
//         console.log("we finished all the uploads, Tus works", aa);
//     },
// });
uppy.on("upload-success", (file, response) => {
    // console.log("USCS",response)
    // console.log(file.name, response.uploadURL);
    // const img = new Image();
    // img.width = 300;
    // img.alt = file.id;
    // img.src = response.uploadURL;
    // document.body.appendChild(img);
    openModal.value = false
    upload_name = response.body.data
    model.value = response.body.data
    uploadedLink.value = response.body.data
    // upload_name.value = file.name
    // model.value = response.uploadURL
    // uploadedLink.value = response.uploadURL
});
function abort() {
    // console.log(uppy.getFiles()[0].id)
    uppy.removeFile(uppy.getFiles()[0].id)
    model.value = undefined

}
</script>